var render = function () {
  var _vm$rftBid2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "rft-active-product",
    attrs: {
      "id": "rft-active-product-modal",
      "title": "Product Edit & Bids",
      "size": "xl",
      "scrollable": true
    },
    on: {
      "hidden": function ($event) {
        return _vm.close();
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "float-right mt-2"
        }, [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-secondary"
          },
          on: {
            "click": function ($event) {
              return _vm.closeModal();
            }
          }
        }, [_c('mdicon', {
          attrs: {
            "name": "close-box",
            "size": 18
          }
        }), _vm._v(" Close ")], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-tabs', {
    attrs: {
      "card": "",
      "lazy": ""
    },
    on: {
      "activate-tab": _vm.handleChangeTab
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "product",
      "to": "#product",
      "lazy": true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('mdicon', {
          attrs: {
            "name": "pill",
            "size": 18
          }
        }), _vm._v(" Product Pack ")];
      },
      proxy: true
    }])
  }, [_c('RftEditActiveProductEditPack'), _vm.isSupplier ? _c('EditorButtonGroup') : _vm._e()], 1), !_vm.creating ? _c('b-tab', {
    attrs: {
      "href": "#specifications",
      "lazy": true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('mdicon', {
          attrs: {
            "name": "tag",
            "size": 18
          }
        }), _vm._v(" Specifications ")];
      },
      proxy: true
    }], null, false, 501728323)
  }, [_c('RftBidsSpecTab', {
    attrs: {
      "rft": _vm.rftBid
    },
    on: {
      "updateSpec": function ($event) {
        var _vm$rftBid;
        return _vm.allowBid((_vm$rftBid = _vm.rftBid) === null || _vm$rftBid === void 0 ? void 0 : _vm$rftBid.uuid);
      }
    }
  })], 1) : _vm._e(), !_vm.creating ? _c('b-tab', {
    attrs: {
      "href": "#documents",
      "lazy": true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('mdicon', {
          attrs: {
            "name": "file-document-multiple",
            "size": 18
          }
        }), _vm._v(" Documents ")];
      },
      proxy: true
    }], null, false, 3329283930)
  }, [_c('RftBidsDocumentTab', {
    attrs: {
      "rft": _vm.rftBid
    }
  })], 1) : _vm._e(), _c('b-tab', {
    attrs: {
      "href": "#bid",
      "disabled": !_vm.lockBid.includes((_vm$rftBid2 = _vm.rftBid) === null || _vm$rftBid2 === void 0 ? void 0 : _vm$rftBid2.uuid) && _vm.isCurrent && _vm.isSupplier && (!_vm.rftBid || _vm.rftBid.specifications === undefined || !_vm.rftBid.specifications.length),
      "lazy": true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('mdicon', {
          attrs: {
            "name": "gavel",
            "size": 18
          }
        }), _vm._v(" Bid ")];
      },
      proxy: true
    }])
  }, [_c('RftEditActiveProductEditBid'), _vm.isSupplier ? _c('EditorButtonGroup') : _vm._e()], 1), _vm.isBuyer ? _c('b-tab', {
    attrs: {
      "href": "#clinical-evaluation",
      "lazy": true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('mdicon', {
          attrs: {
            "name": "checkbox-multiple-marked",
            "size": 18
          }
        }), _vm._v(" Clinical Evaluation ")];
      },
      proxy: true
    }], null, false, 2076150449)
  }, [_c('RftClinicalEvaluationTab', {
    attrs: {
      "tabClick": _vm.tabClick
    }
  })], 1) : _vm._e(), _vm.isBuyer ? _c('b-tab', {
    attrs: {
      "href": "#compare-specs",
      "lazy": true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('mdicon', {
          attrs: {
            "name": "text-search",
            "size": 18
          }
        }), _vm._v(" Bid Product Search ")];
      },
      proxy: true
    }], null, false, 3204122123)
  }, [_c('RftBidProductSearchTab')], 1) : _vm._e(), _vm.isBuyer ? _c('b-tab', {
    attrs: {
      "href": "#comment-buyer",
      "lazy": true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('mdicon', {
          attrs: {
            "name": "comment",
            "size": 18
          }
        }), _vm._v(" Comment ")];
      },
      proxy: true
    }], null, false, 1372688287)
  }, [_c('RftCommentBuyerTab')], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }