var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }]
  }, [_c('p', [_vm._v(" In this step the evaluation team is able to score the products using the SA Health Clinical evaluation method. Notes can be added as well for future reference. The evaluation team also has access to product images which have been supplied by the bidders ")]), _c('div', {
    staticClass: "col-md-12"
  }, [_vm._l(_vm.tableDataBids, function (product, index) {
    return _c('div', {
      key: index,
      staticClass: "mb-3"
    }, [_c('el-card', {
      key: index,
      staticClass: "box-card"
    }, [_c('div', {
      staticClass: "clearfix"
    }, [_c('div', {
      staticClass: "mt-2",
      staticStyle: {
        "float": "left"
      }
    }, [_c('strong', {
      domProps: {
        "innerHTML": _vm._s(product.requested_product_name_list_mode)
      }
    }), _c('br'), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.min_shelf_life_months) + " ")]), _vm._v(_vm._s(product.min_shelf_life_months) + " month(s) | "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.current_brand))]), _vm._v(" " + _vm._s(product.current_brand) + " | "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.current_supplier))]), _vm._v(" " + _vm._s(product.current_supplier) + " | "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.current_unit_measure))]), _vm._v(" " + _vm._s(product.current_unit_measure)), _c('br'), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.unit_quantity))]), _vm._v(" " + _vm._s(product.unit_quantity) + " "), _c('a', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'collapse-' + index,
        expression: "'collapse-' + index"
      }],
      attrs: {
        "href": "#",
        "aria-expanded": "true"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('mdicon', {
      staticClass: "when-open",
      attrs: {
        "name": "chevron-up",
        "size": 20
      }
    }), _c('mdicon', {
      staticClass: "when-closed",
      attrs: {
        "name": "chevron-down",
        "size": 20
      }
    })], 1)])])]), _c('b-collapse', {
      staticClass: "mt-2",
      attrs: {
        "visible": "",
        "id": 'collapse-' + index
      }
    }, [_c('el-card', {
      key: index,
      staticClass: "box-card"
    }, [_c('el-table', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "data": product.bids,
        "row-class-name": _vm.tableRowClassName
      }
    }, [_c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.trade_product_name
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_vm._v(" " + _vm._s(scope.row.organisation_name)), _c('br'), scope.row.bundle_id ? _c('mdicon', {
            attrs: {
              "name": "package-variant-closed",
              "size": 18
            }
          }) : _vm._e(), _vm._v(" " + _vm._s(scope.row.trade_product_name) + " ")];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": "",
        "width": "120"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_c('el-button', {
            attrs: {
              "size": "small"
            },
            on: {
              "click": function ($event) {
                $event.preventDefault();
                return _vm.openDetail(scope.row, product);
              }
            }
          }, [_vm._v(" Detail ")])];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.weight,
        "width": "90"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_vm._v(" " + _vm._s(scope.row.weight) + " ")];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.bid_comment,
        "width": "90"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          var _scope$row, _scope$row$raw, _scope$row3, _scope$row3$raw, _scope$row4, _scope$row4$raw;
          return [_c('el-popover', {
            attrs: {
              "trigger": "hover",
              "content": scope === null || scope === void 0 ? void 0 : (_scope$row = scope.row) === null || _scope$row === void 0 ? void 0 : (_scope$row$raw = _scope$row.raw) === null || _scope$row$raw === void 0 ? void 0 : _scope$row$raw.comment_buyer
            },
            scopedSlots: _vm._u([{
              key: "reference",
              fn: function () {
                var _scope$row2, _scope$row2$raw;
                return [_c('el-button', {
                  attrs: {
                    "type": "text"
                  },
                  on: {
                    "click": function ($event) {
                      $event.preventDefault();
                      return _vm.openComment(scope.row, product);
                    }
                  }
                }, [scope !== null && scope !== void 0 && (_scope$row2 = scope.row) !== null && _scope$row2 !== void 0 && (_scope$row2$raw = _scope$row2.raw) !== null && _scope$row2$raw !== void 0 && _scope$row2$raw.comment_buyer ? _c('mdicon', {
                  attrs: {
                    "name": "comment-outline",
                    "size": 18
                  }
                }) : _c('mdicon', {
                  attrs: {
                    "name": "plus-circle",
                    "size": 18
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }), scope && scope.row && scope.row.raw && scope.row.raw.comment_supplier && (scope === null || scope === void 0 ? void 0 : (_scope$row3 = scope.row) === null || _scope$row3 === void 0 ? void 0 : (_scope$row3$raw = _scope$row3.raw) === null || _scope$row3$raw === void 0 ? void 0 : _scope$row3$raw.comment_supplier) != '' ? _c('el-popover', {
            attrs: {
              "trigger": "hover",
              "content": scope === null || scope === void 0 ? void 0 : (_scope$row4 = scope.row) === null || _scope$row4 === void 0 ? void 0 : (_scope$row4$raw = _scope$row4.raw) === null || _scope$row4$raw === void 0 ? void 0 : _scope$row4$raw.comment_supplier
            },
            scopedSlots: _vm._u([{
              key: "reference",
              fn: function () {
                return [_c('mdicon', {
                  attrs: {
                    "name": "comment-outline",
                    "size": 18
                  }
                })];
              },
              proxy: true
            }], null, true)
          }) : _vm._e()];
        }
      }], null, true)
    })], 1)], 1)], 1)], 1);
  }), _c('div', {
    staticClass: "rft-active-product-modal"
  }, [_c('RftEditActiveProductModal', {
    ref: "RftEditActiveProductModal"
  })], 1)], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }